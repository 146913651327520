export const SESSION_BANNER_FILE_RESOURCE_FRAGMENT = `
  fragment sessionBannerFileResourceFragment on Session {
    uid
    name
    bannerFileResource {
      uid
      fileName
      fileType
      path
      schemaCode
    }
  }
`;
